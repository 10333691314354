import formStyles from '../Forms.module.scss';
import { useForm } from "react-hook-form";
import { schema } from "../../schema/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../api";
import { ButtonLoader } from "../../ui";
import { useState } from "react";
import SuccessText from "../../ui/SuccessText/SuccessText";

function SellerConfForm() {
    const [formSubmitted, setFormSubmitted] = useState(false); // Новое состояние для отслеживания отправки формы
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const fields = {
        sellerName: "Ваше имя",
        sellerCompany: "Название вашей компании",
        sellerEmail: "Ваш email",
        distributorCompany: "Название компании поставщика",
        distributorEmail: "Email поставщика",
        comment: "Комментарий",
    };

    const onSubmit = async (data) => {
        try {
            const response = await api.sellerApi.sendSellerInfo(data);
            reset();

            if (response.status === true) {
                setSuccessMessage(response.message);
                setErrorMessage(null);
                setAlertMessage(null);
                setFormSubmitted(true); // Устанавливаем состояние успешной отправки
            } else {
                setErrorMessage(response.message);
                setSuccessMessage(null);
            }

        } catch (e) {
            console.log(e);
            setErrorMessage("Ошибка при отправке данных");
            setSuccessMessage(null);
        }
    };

    const handleClick = (event) => {
        event.preventDefault();
        if (!isValid) {
            setAlertMessage("Заполните все поля");
        } else {
            setAlertMessage(null);
            handleSubmit(onSubmit)();
        }
    };

    return (
        <div className={formStyles.formWrapper}>
            <h3 className={formStyles.title}>Пригласить поставщика</h3>
            {formSubmitted ? (
                <SuccessText message={successMessage}/>
            ) : (
                <form className={formStyles.form}>
                    {Object.entries(fields).map(([key, value]) => (
                        <div key={key} className={formStyles.formField}>
                            <label className={formStyles.label}>{value}</label>
                            {key === "comment" ? (
                                <textarea
                                    className={formStyles.textarea}
                                    {...register(key)}
                                    rows={4}
                                />
                            ) : (
                                <input
                                    className={formStyles.input}
                                    {...register(key)}
                                />
                            )}
                            {errors[key] && (
                                <span className={formStyles.invalidMessage}>{errors[key].message}</span>
                            )}
                        </div>
                    ))}
                    <ButtonLoader
                        load={isSubmitting}
                        title={"Пригласить"}
                        onClick={handleClick}
                        disabled={isSubmitting}
                    />
                    {alertMessage && <p className={formStyles.alertMessage}>{alertMessage}</p>}
                    {errorMessage && <p className={formStyles.errorMessage}>{errorMessage}</p>}
                </form>
            )}
        </div>
    );
}

export default SellerConfForm;
