import {useTranslation} from "react-i18next";
import preloaderImg from "../img/tube-spinner.svg";

const AuthCountiesModal = () => {

	const {t} = useTranslation();

	// Визуализация компоненты
	return (
		<div className={'i_auth_countries'}>
			<div className="i_form-title">
				<span>{t('I_COUNTRIES')}</span>
			</div>
			<a href="https://apicore.kz/">
				<div className="i_form-item">
					<span>{t('I_KZ')}</span>
				</div>
			</a>

			<a href="https://apicore.uz/">
				<div className="i_form-item">
					<span>{t('I_UZ')}</span>
				</div>
			</a>

			<a href="https://apicore.kg/">
				<div className="i_form-item">
					<span>{t('I_KG')}</span>
				</div>
			</a>

		</div>
	);
}

export default AuthCountiesModal;
