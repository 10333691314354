import closeBtn from '../img/closeModal.svg'
import {useDispatch, useSelector} from "react-redux";

import '../styles/Modal.scss'

import AuthForm from "../modal/authForm";
import ResetPasswordForm from "./restorePasswordForm";
import OrderWin from "../modal/orderWin";
import ContactsModal from "./contactsModal";
import {useEffect} from "react";
import AuthCountiesModal from "../modal/AuthCountriesModal";
import TexpoRegisterUser from "../modal/TexpoRegisterUser";
import ModalCatalogList from "../modal/ModalCatalogList";
import DealerCatalogProductDetailModal from "../modal/DealerCatalogProductDetailModal";
import CategoryListMobileModal from "../modal/CategoryListMobileModal";
import QRModal from "../modal/components/QRModal";
import FavoriteProductListModal from "../modal/FavoriteProductListModal";

const MultiModal = () => {

	const dispatch = useDispatch();
	const modals = useSelector(state => state.multiModal.modals);

	useEffect(() => {
		if(modals[0].modalIsOpen){
			document.body.style.overflowY = 'hidden';
		}else{
			document.body.style.overflowY = 'auto';
		}
	}, [modals[0].modalIsOpen]);

	const closeModal = (modalLevel) => {
		dispatch({type: "CLOSE_MODAL", modalLevel: modalLevel})

		const url = new URL(window.location);
		url.search = ''; // Очистка всех параметров
		window.history.pushState({}, '', url);
	}

	return (
		modals.map((modal, index) => {
			return (
				<div className={
					`i_multi_modal ${!modal.modalIsOpen ? 'hide' : ''}
					 ${modal.modalType === 'contacts' ? 'i_contacts-modal' : ''} 
					 ${modal.modalWidth !== null ? modal.modalWidth : ''}
					 modal-level-${modal.modalLevel}
					 `
				} key={index}>
					<div className="i_container">
						<div className={`i_multi_modal-close`} onClick={() => {
							closeModal(modal.modalLevel)
						}}>
							<img src={closeBtn} alt="close"/>
						</div>

						{/*В зависимоти от переданного значения будет открываться определенное модальное окно*/}
						{modal.modalType === 'authFormModal' && <AuthForm/>}
						{modal.modalType === 'authCountiesModal' && <AuthCountiesModal/>}
						{modal.modalType === 'resetPasswordFormModal' && <ResetPasswordForm/>}
						{modal.modalType === 'orderWinModal' && <OrderWin/>}
						{modal.modalType === 'contacts' && <ContactsModal/>}
						{modal.modalType === 'texpoRegisterUser' && <TexpoRegisterUser/>}
						{modal.modalType === 'modalCatalogList' && <ModalCatalogList/>}
						{modal.modalType === 'dealerCatalogProductDetailModal' && <DealerCatalogProductDetailModal/>}
						{modal.modalType === 'categoryListMobileModal' && <CategoryListMobileModal/>}
						{modal.modalType === 'qrModal' && <QRModal />}
						{modal.modalType === 'favoriteProductListModal' && <FavoriteProductListModal />}
					</div>
				</div>
			)
		})

	)
}

export default MultiModal;