import styles from './SuccessText.module.scss';
import {useSelector, useDispatch} from "react-redux";
import {ButtonLoader} from "../index";

function SuccessText({message}) {

	const dispatch = useDispatch();
	const isAuthorized = useSelector(state => state.userData.authorization);

	function openRegisterModal() {
		dispatch({type:'OPEN_MODAL', modalType: 'authFormModal', modalLevel: 1,  isAuth: true})
	}

	return (
		<div className={styles.successText}>
			<div dangerouslySetInnerHTML={{__html: message}}></div>
			{!isAuthorized && (
				<div className={styles.button}>
					<ButtonLoader title="Зарегистрироваться" onClick={openRegisterModal} />
				</div>
			)}
		</div>
	);
}

export default SuccessText;