import logo from '../img/logo_horizontal.svg';
import avatar from '../img/avatar.svg';
import burger from '../img/burger.svg';
import sellerConf from '../img/sellerconf/logoSellers.svg';
import Texpo from '../img/texpo/texpo_logo.png';
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import useLocalStorage from '../hooks/useLocalStorage'
import i18n from "../i18n";
import {useTranslation} from 'react-i18next'
import LanguageSelector from "./LanguageSelector";
import BurgerMenu from "./burgerMenu";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../utils/config";
import {useLocation} from "react-router-dom";
import {getParameterByName} from "../utils/helpers";


const Header = () => {
	const currentDomain = window.location.hostname; // Получаем текущий домен
	const domainSegments = currentDomain.split('.'); // Разбиваем домен на сегменты
	const lastSegment = domainSegments.pop(); // Получаем последний сегмент домена
	const [isBurgerOpen, setIsBurgerOpen] = useState(false); // Состояние для бургер-меню
	const [countryName, setCountryName] = useState(''); // Состояние для названия страны

	const authorized = useSelector(state => state.userData.authorization); // Проверка авторизации пользователя
	const userData = useSelector(state => state.userData); // Проверка авторизации пользователя

	const dispatch = useDispatch(); // Использование диспетчера Redux
	const queryString = window.location.search;

	// const lastSegment = 'uz'; // Получаем последний сегмент домена

	const location = useLocation().pathname; // Получаем текущий URL
	const modals = useSelector(state => state.multiModal.modals); // Получаем массив модальных окон

	// Обработчик закрытия модальных окон при нажатии на Escape
	const modalCloseOnEnter = (event) => {
		if (event.key === 'Escape') {
			// Проходимся по массиву c конца , и если модальное окно открыто , то закрываем его и выходим с цикла
			// Метод find() находит первый открытый модал.
			const openModal = modals.slice().reverse().find(modal => modal.modalIsOpen === true);

			console.log(openModal)
			if(openModal.modalLevel === 1){
				const url = new URL(window.location);
				url.search = ''; // Очистка всех параметров
				window.history.pushState({}, '', url);
			}
			if (openModal) {
				dispatch({ type: "CLOSE_MODAL", modalLevel: openModal.modalLevel });
			}
		}
	}

	useEffect(() => {
		document.addEventListener('keydown', modalCloseOnEnter);
		return () => {
			document.removeEventListener('keydown', modalCloseOnEnter); // Удаляем обработчик при размонтировании
		};
	}, [modals]);

	const {t} = useTranslation(); // Переводы
	const [language, setLanguage] = useLocalStorage('language', lastSegment === 'one' ? 'en' : 'ru'); // Локальное хранилище для языка

	useEffect(() => {
		i18n.changeLanguage(language); // Изменение языка при загрузке
	}, []);

	// Установка текущего языка и перезагрузка страницы
	const setCurrentLanguage = (el) => {
		i18n.changeLanguage(el);
		setLanguage(el);
		window.location.reload();
	}

	// Проверка подтверждения по email
	const checkConfirm = () => {
		const confirmValue = getParameterByName('confirm', queryString);
		const emailValue = getParameterByName('email', queryString);
		const secretValue = getParameterByName('secret', queryString);

		if (confirmValue === 'y') {
			fetch(`${API_URL}/auth/confirm`, {
				method: 'POST',
				body: JSON.stringify({'email' : emailValue, 'confirm_secret' : secretValue}),
			}).then(response => response.json()).then(data => {
				if(data.status === true && data.confirm === true) {
					dispatch({type: "OPEN_MODAL", modalLevel : 1, modalType: 'authFormModal', modalIsOpen: true, modalData : {isConfirm : 'done', emailValue : emailValue}});
				} else if(data.status === false) {
					dispatch({type: "OPEN_MODAL", modalLevel : 1, modalType: 'authFormModal', modalIsOpen: true, modalData : {isConfirm : 'error'}});
				}
			});
		}
	}

	// Проверка сброса пароля
	const checkConfirmReset = () =>{

		const restoreValue = getParameterByName('restorepass', queryString);

		if (restoreValue === 'y') {
			dispatch({type: "OPEN_MODAL", modalLevel : 1, modalType: 'resetPasswordFormModal', modalIsOpen: true});
		}
	}

	// Открытие/закрытие бургер-меню
	const openBurgerMenu = () => {
		setIsBurgerOpen(!isBurgerOpen);
	}

	// Изменение названия страны в шапке
	const changeCountryName = () => {
		switch (lastSegment) {
			case 'kz':
				setCountryName(t('I_KZ'));
				break;
			case 'uz':
				setCountryName(t('I_UZ'));
				break;
			case 'one':
				setCountryName('Global');
				break;
			case 'kg':
				setCountryName(t('I_KG'));
				break;
			default:
				setCountryName(t('I_KZ'));
				break;
		}
	}

	useEffect(() => {
		checkConfirm();
		checkConfirmReset();
		changeCountryName();
	},[]);

	return (
		<>
			{location !== '/sellerconf' && lastSegment === 'uz' ? (<Link className='i_sellerconf-banner' to='/sellerconf'><div className="i_sellerconf-banner-img"></div></Link>) : ''}
            {location !== '/texpo' && lastSegment === 'kg' ? (<Link className='i_texpo-banner' to='/texpo'><div className="i_texpo-banner-img"></div></Link>) : ''}

			<header>
				<Link to="/" className='i_logo'>
					<img src={`${logo}`} alt="Logo"/>
					<div className="i_country-title">
						<span>{countryName}</span>
					</div>
				</Link>
				<div className="i_nav_links">

					<nav>
						<Link to="/for_distributors">{t('I_DISTRIBUTORS')}</Link>
						<Link to="/for_resellers">{t('I_RESELLERS')}</Link>
					</nav>

					{location !== '/sellerconf' && lastSegment === 'uz' ? (<div className="iConfBtn sellerConf"><Link to='/sellerconf'><img src={`${sellerConf}`} alt="sellerconf"/></Link></div>) : ''}
                    {location !== '/texpo' && lastSegment === 'kg' ? (<div className="iConfBtn texpoConf"><Link to='/texpo'><img src={`${Texpo}`} alt="texpo"/></Link></div>) : ''}
				</div>
				<div className="i_block_top">
					<div className='i_change_language'>
						<LanguageSelector setCurrentLanguage={setCurrentLanguage} language={language}/>
					</div>
					{userData.hideAuthBtn !== true ? authorized ?
							<>
								<div className="i_header_account">
									<a href={`${lastSegment === 'd' ? 'http' : 'https'}://cabinet.${currentDomain}`}
									   className='i_cabinet_button'>
										<img src={`${avatar}`} alt="user"/>
										<span>{t('I_PERSONAL_AREA')}</span>
									</a>
								</div>
							</>

							:
							<>
								<div className={`i_header_account`} onClick={() => {
									dispatch({
										type: "OPEN_MODAL",
										modalLevel : 1,
										modalType: lastSegment === 'one' ? 'authCountiesModal' : 'authFormModal',
										modalIsOpen: true,
										isAuth: false
									})
								}}>
									<div className='i_cabinet_button'>
										<img src={`${avatar}`} alt="user"/>
										{t('I_PERSONAL_AREA')}
									</div>

								</div>
							</>
						: <></>
					}
					<div className='i_header_burger' onClick={openBurgerMenu}>
						<img src={`${burger}`} alt="burger"/>
					</div>
				</div>
			</header>

			<BurgerMenu
				isBurgerOpen={isBurgerOpen}
				setIsBurgerOpen={setIsBurgerOpen}
				userData={userData}
			/>
		</>
	)
}

export default Header;
