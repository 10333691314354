import React from "react";
import multiFetch from "../../utils/multiFetch";

class BaseApi {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    fetchData(endpoint, body) {
        return multiFetch(`${this.baseUrl}/${endpoint}`, body);
    }
}

export default BaseApi;