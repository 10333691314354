import styles from "./SellerConfText.module.scss";


function SellerConfText() {
    return (
        <div className={styles.sellerConfText}>
            <p>Заполните форму, чтобы пригласить вашего поставщика присоединиться к платформе Apicore.<br/>
                Apicore предоставляет удобные инструменты для интеграции вашего бизнеса с поставщиками, упрощая управление товарами, остатками, заказами и другими процессами.
                Платформа обеспечивает прозрачность операций и минимизирует вероятность ошибок.
            </p>
            Это поможет вам:
            <ul>
                <li>Подключиться к поставщику для автоматизации обмена данными</li>
                <li>Легче управлять товарами, остатками и логистикой, отслеживая все процессы в режиме реального времени</li>
                <li>Повысить эффективность взаимодействия и улучшить совместные процессы, что приведет к более слаженной работе и лучшему обслуживанию клиентов
                </li>
            </ul>
            <p>
                Пожалуйста, предоставьте как можно более точную информацию о поставщике, чтобы мы могли быстро обработать запрос. Поставщик получит электронное письмо с детальными инструкциями по регистрации и интеграции на Apicore.
            </p>
        </div>
    );
}

export default SellerConfText;
