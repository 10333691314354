import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Layout from "./components/layout";
import PaymentPage from "./pages/PaymentPage";
import PrivacyPage from "./pages/PrivacyPage";
import RetailChains from "./pages/RetailChains";
import CheckAuth from "./modal/checkAuth";
import ForResellers from "./pages/ForResselers";
import ForDistributors from "./pages/ForDistributors";
import OffertaPage from "./pages/OffertaPage";
import TexpoPage from "./pages/TexpoPage";
import SellerConfPage from "./pages/SellerConfPage";

function App() {
	return (
		<div className={`App`}>
			<CheckAuth>
				<Routes>
					<Route
						path="/"
						element={
							<Layout />
						}
					>
						<Route index element={<HomePage />} />
						<Route path="/payment" element={<PaymentPage />} />
						<Route path="/privacy" element={<PrivacyPage />} />
						<Route path="/for_distributors" element={<ForDistributors />} />
						<Route path="/for_resellers" element={<ForResellers />} />
						<Route path="/retail_chains" element={<RetailChains />} />
						<Route path="/offerta" element={<OffertaPage />} />
						<Route path="/texpo" element={<TexpoPage />} />
						<Route path="/sellerconf" element={<SellerConfPage />} />
					</Route>
				</Routes>
			</CheckAuth>

		</div>
	);
}

export default App;
