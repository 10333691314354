import useLocalStorage from "../hooks/useLocalStorage";
import {useEffect, useState} from "react";
import {API_URL} from "../utils/config";
import {hideProjector} from "../utils/helpers";

function PaymentPage() {

	const [language, setLanguage] = useLocalStorage('language', 'ru');

	const [data, setData] = useState(null);

	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();

	const getData = () => {
		fetch(`${API_URL}/site/payment/`, {
			method: 'POST',
			body: JSON.stringify({language: language}),
			credentials: 'include'
		}).then(response => response.text()).then(data => {
			setData(data)
		})
	}

	useEffect(getData, [])

    hideProjector()


	return (
		<>
			{data && (
				<>
					<div className="i_wrapper" dangerouslySetInnerHTML={{ __html: data }}></div>
				</>
			)}
		</>
	);
}

export default PaymentPage;
