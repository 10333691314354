import {TexpoApi} from "./events/TexpoApi";
import SellerConfAPI from "./events/SellerConfAPI";

export class Api {
	constructor() {
		this.texpoApi = new TexpoApi()
        this.sellerApi = new SellerConfAPI()
	}
}

export const api = new Api()