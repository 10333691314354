import {Link} from "react-router-dom";
import {PROJECTOR} from "../utils/constants";

const RetailChains = () =>{

    PROJECTOR.style.display = 'none'

	return(
		<div className={'i_wrapper'}>
			<h1>Торговым сетям</h1>
			Конфиденциальность
			<br/><br/>

			1. Определения
			<br/><br/>

			Интернет проект apicore.kz (далее – URL, «мы») серьезно относится к вопросу конфиденциальности информации своих клиентов и посетителей сайта apicore.kz (далее – «вы», «посетители сайта»). Персонифицированной мы называем информацию, содержащую персональные данные (например: ФИО, логин или название компании) посетителя сайта, а также информацию о действиях, совершаемых вами на сайте URL. (например: заказ посетителя сайта с его контактной информацией). Анонимными мы называем данные, которые невозможно однозначно идентифицировать с конкретным посетителем сайта (например: статистика посещаемости сайта).
			<br/><br/>

			2. Использование информации
			<br/><br/>

			Мы используем персонифицированную информацию конкретного посетителя сайта исключительно для обеспечения ему качественного оказания услуг и их учета. Мы не раскрываем персонифицированных данных одних посетителей сайта URL другим посетителям сайта. Мы никогда не публикуем персонифицированную информацию в открытом доступе и не передаем ее третьим лицам. Исключением являются лишь ситуации, когда предоставление такой информации уполномоченным государственным органам предписано действующим законодательством Республики Казахстан. Мы публикуем и распространяем только отчеты, построенные на основании собранных анонимных данных. При этом отчеты не содержат информацию, по которой было бы возможным идентифицировать персонифицированные данные пользователей услуг. Мы также используем анонимные данные для внутреннего анализа, целью которого является развитие продуктов и услуг URL
			<br/><br/>

			3. Ссылки
			<br/><br/>

			Сайт apicore.kz может содержать ссылки на другие сайты, не имеющие отношения к нашей компании и принадлежащие третьим лицам. Мы не несем ответственности за точность, полноту и достоверность сведений, размещенных на сайтах третьих лиц, и не берем на себя никаких обязательств по сохранению конфиденциальности информации, оставленной вами на таких сайтах.
			<br/><br/>

			4. Ограничение ответственности
			<br/><br/>

			Мы делаем все возможное для соблюдения настоящей политики конфиденциальности, однако, мы не можем гарантировать сохранность информации в случае воздействия факторов находящихся вне нашего влияния, результатом действия которых станет раскрытие информации. Сайт apicore.kz и вся размещенная на нем информация представлены по принципу "как есть” без каких-либо гарантий. Мы не несем ответственности за неблагоприятные последствия, а также за любые убытки, причиненные вследствие ограничения доступа к сайту URL или вследствие посещения сайта и использования размещенной на нем информации.
			<br/><br/>

			5. Контакты
			<br/><br/>

			По вопросам, касающимся настоящей политики, просьба обращаться по адресу&nbsp;
			<Link to="mailto:info@ilab.kz">info@apicore.one</Link>.
			<br/><br/>
			<p>
				ТОО "iLab"
			</p>
			<p>
				БИН: 070640009562
			</p>
			<p>
				Офис поддержки пользователей: 050040, Казахстан, г. Алматы, ул. Байзакова, д. 303, 1й Этаж, офис: 111
			</p>
			<p>
				Email: <a href="mailto:info@apicore.kz">info@apicore.one</a>
			</p>
			<br/>
			<br/>
		</div>
	)
}

export default RetailChains;