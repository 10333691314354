import { useEffect } from "react";
import {useDispatch} from "react-redux";
import {API_URL} from "../utils/config";

const CheckAuth = ({ children, setUserData}) => {
	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();

	const dispatch = useDispatch();

	useEffect(() => {
		const requestOptions = {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify({}),
		};

		const isAuth = () => {
			fetch(`${API_URL}/auth/isauthorized`, requestOptions)
				.then(response => response.json())
				.then(data => {
					if(data.authorized === true){
						dispatch({type: "SET_USER_DATA", authorization: true})
					}else{
						dispatch({type: "SET_USER_DATA", authorization: false})
					}
				})
				.catch(error => {
					console.log(error)
					dispatch({type: "SET_USER_DATA", authorization: false})
				});
		};

		isAuth();
	}, []);

	return <>{children}</>;
};

export default CheckAuth;
