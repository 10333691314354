import * as yup from 'yup';
import {regExpEmail} from "../constants";

export const schema = yup.object().shape({
    sellerName: yup.string().trim().required('Обязательное поле').min(2, 'Имя - минимум 2 символа'),
    sellerCompany: yup.string().trim().required('Обязательное поле').min(2, 'Название - минимум 2 символа'),
    sellerEmail: yup.string().required('Обязательное поле').matches(regExpEmail, 'Неверный формат почты'),
    distributorCompany: yup.string().trim().required('Обязательное поле').min(2, 'Название - минимум 2 символа'),
    distributorEmail: yup.string().required('Обязательное поле').matches(regExpEmail, 'Неверный формат почты'),
    comment: yup.string()
})