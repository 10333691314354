import logo from '../img/texpo/texpo_logo.png';
import whatsapp from '../img/texpo/whatsapp.svg';
import telegram from '../img/texpo/telegram.svg';
import youtube from '../img/texpo/youtube.svg';
import instagram from '../img/texpo/instagram.svg';
import qrCode from '../img/texpo/qr-code.svg';
import Cookies from 'js-cookie';
import '../styles/TexpoPage.scss';
import {useEffect, useRef, useState} from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";
import {ReactSVG} from "react-svg";
import starFilled from '../img/texpo/star-filled.svg';
import useURLParamWatcher from "../hooks/useURLParamWatcher";
import {hideProjector} from "../utils/helpers";

// Компонент TexpoPage
const TexpoPage = () => {

	// Состояние для хранения данных, полученных от API
	const [data, setData] = useState(null);

	// Получаем текущий язык из локального хранилища, если его нет, используем 'ru' по умолчанию
	const [language, setLanguage] = useLocalStorage('language', 'ru');

	// Хук useDispatch для отправки действий в Redux
	const dispatch = useDispatch();

	// Получаем авторизацию пользователя
	const authorized = useSelector(state => state.userData.authorization);

	// Функция для получения данных с API
	const getData = async () => {
		// Отправляем POST-запрос к API для получения списка компаний
		const response = await api.texpoApi.getCompanyList()

		if(response.status === true){
			setData(response)
		}
	}

	const openQRModal = (item) => {
		dispatch({type: "OPEN_MODAL", modalType: 'qrModal', modalLevel : 1, modalData: {item: item}});
	}

	// Функция для открытия модального окна при нажатии на "Смотреть каталог"
	const openTexpoModal = async (item) => {
		// Получаем идентификатор пользователя из cookie
		const texpoIDUser = Cookies.get('texpo_user_id');
		// Если пользователь идентифицирован по cookie, просто выводим в консоль
		if(texpoIDUser || authorized){
			const newUrl = new URL(window.location);
			newUrl.searchParams.set('catalog', item.catalog.hash);
			window.history.pushState({}, '', newUrl);
		}else{
			// Если пользователь не идентифицирован, открываем модальное окно для регистрации
			dispatch({type: "OPEN_MODAL", modalType: 'texpoRegisterUser', modalLevel : 1,  modalData: {hash: item.catalog.hash}});
		}
	}

	const openFavoriteProductList = () => {
		dispatch({type: "OPEN_MODAL", modalType: 'favoriteProductListModal', modalLevel: 1});
	}

	// Используем useEffect для получения данных при первой загрузке компонента
	useEffect(()=>{
		getData()
	}, [])

	useURLParamWatcher('catalog', (catalogHash) => {
		// Получаем идентификатор пользователя из cookie
		const texpoIDUser = Cookies.get('texpo_user_id');
		if(catalogHash !== null){
			if(texpoIDUser || authorized) {
				dispatch({
					type: "OPEN_MODAL",
					modalType: 'modalCatalogList',
					modalLevel: 1,
					modalData: {hash: catalogHash},
					modalWidth: 'large'
				});
			}else{
				dispatch({type: "OPEN_MODAL", modalType: 'texpoRegisterUser', modalLevel : 1,  modalData: {hash: catalogHash}});
			}
		}
	})

    // Скрываем элемент с id 'projector' при загрузке страницы
    hideProjector()

	return (
		<div className="texpo">
			{/* Верхний блок страницы Texpo */}
			<div className="texpo-upper">
				<div className="texpo-upper__items">

					{/* Логотип Texpo */}
					<div className="texpo-upper__item logo">
						<div className="texpo-upper__item-img">
							<img src={logo} alt="logo"/>
						</div>
					</div>

					{/* Элементы с различными категориями товаров */}
					<div className="texpo-upper__item odejda">
						<div className="texpo-upper__item-title">
							<span>Одежда</span>
						</div>
					</div>

					<div className="texpo-upper__item tkani">
						<div className="texpo-upper__item-title">
							<span>Ткани</span>
						</div>
					</div>

					<div className="texpo-upper__item furnitura">
						<div className="texpo-upper__item-title">
							<span>Фурнитура</span>
						</div>
					</div>

					<div className="texpo-upper__item description">
						<div className="texpo-upper__item-title">
							<span>Крупнейшее мероприятие года в сфере моды и текстиля</span>
						</div>
					</div>

					<div className="texpo-upper__item oborudovaniye">
						<div className="texpo-upper__item-title">
							<span>Швейное оборудование</span>
						</div>
					</div>

					<div className="texpo-upper__item uslugi">
						<div className="texpo-upper__item-title">
							<span>Услуги</span>
						</div>
					</div>

				</div>
			</div>

			{/* Нижний блок страницы Texpo */}
			<div className="texpo-lower">
				<div className="texpo-lower__head">
					<div className="texpo-lower__title">
						<span>Экспоненты</span>
					</div>
					<div className="texpo-lower__favorite" onClick={openFavoriteProductList}>
						<span>Избранное</span>
						<ReactSVG src={starFilled}/>
					</div>
				</div>
				<div className="texpo-lower__items">
					{/* Рендеринг списка экспонентов из полученных данных */}
					{data && data.distributors.map((item, index) => {
						return (
							<div className="texpo-lower__item" key={index}>
								<div className="texpo-lower__item-name">
									<span>{item.name}</span>
								</div>
								<div className="texpo-lower__item-contacts">
									<div className="texpo-lower__item-contacts__text">
										<span>{item.contact}</span>
										<span>{item.phone}</span>
										<a href={item.web} target={"_blank"}><span>{item.web}</span></a>
									</div>
									<div className="texpo-lower__item-contacts__socials">
										{/* Ссылки на социальные сети экспонентов */}

										{
											item.telegram && (
												<div className="texpo-lower__item-contacts__socials-item">
													<a href={item.telegram}
													   target={"_blank"}>
														<img src={telegram} alt="telegram"/>
													</a>
												</div>
											)
										}

										{
											item.whatsapp && (
												<div className="texpo-lower__item-contacts__socials-item">
													<a href={item.whatsapp}
													   target={"_blank"}>
														<img src={whatsapp} alt="whatsapp"/>
													</a>
												</div>
											)
										}

										{
											item.info?.youtube && (
												<div className="texpo-lower__item-contacts__socials-item">
													<a href={item.info.youtube}
													   target={"_blank"}>
														<img src={youtube} alt="whatsapp"/>
													</a>
												</div>
											)
										}

										{
											item.info?.instagram && (
												<div className="texpo-lower__item-contacts__socials-item">
													<a href={item.info.instagram}
													   target={"_blank"}>
														<img src={instagram} alt="whatsapp" style={{width: 26, height:26}}/>
													</a>
												</div>
											)
										}

									</div>
								</div>
								{/* Кнопка для открытия модального окна с каталогом */}
								<div className={'texpo-lower__item-action'}>
									{
										item.catalog ? (
											<>
												<div className="texpo-lower__item-qr" onClick={()=>{openQRModal(item)}}>
													<ReactSVG src={qrCode}/>
												</div>
												<div className="texpo-lower__item-btn">
													<span onClick={() => {
														openTexpoModal(item)
													}}>Смотреть каталог</span>
												</div>
											</>
										) : (
											<div className="texpo-lower__item-btn">
												<span>Ожидаем публикацию</span>
											</div>
										)
									}
								</div>


							</div>
						)
					})}
				</div>
			</div>
		</div>
	);
}

export default TexpoPage;
