import BaseApi from "./BaseApi";
import {API_URL} from "../../utils/config";
import multiFetch from "../../utils/multiFetch";


class SellerConfAPI extends BaseApi {
    constructor() {
        super(API_URL);
    }

    sendSellerInfo(body){
        return multiFetch(`${API_URL}/site/sellerconf.request.save`, body)
    }
}

export default SellerConfAPI;