import styles from './ButtonLoader.module.scss'

const ButtonLoader = ({load , title, onClick, disabled}) => {
    return (
        <button
            className={`${styles.buttonLoader} ${load ? 'loader' : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            <span>{title}</span>
        </button>
    )
}

export default ButtonLoader