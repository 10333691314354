import {createStore, combineReducers} from "redux";
import {modalReducer} from "./modalReducer";
import {userReducer} from "./userReducer";
import {catalogReducer} from "./catalogReducer";
import {favoriteReducer} from "./favoriteReducer";
import {composeWithDevTools} from "@redux-devtools/extension";

const rootReducer = combineReducers({
	multiModal: modalReducer,
	userData: userReducer,
	catalog: catalogReducer,
	favorite: favoriteReducer,
});

export const store = createStore(rootReducer, composeWithDevTools());

