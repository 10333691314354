import '../styles/SellerConfPage.scss';
import {hideProjector} from "../utils/helpers";
import SellerConfForm from "../utils/forms/SellerConfForm/SellerConfForm";
import styles from "../utils/forms/SellerConfForm/SellerConfForm.module.scss";
import {SellerConfText} from "../utils/ui";

const SellerConfPage = () => {
    const frames = {
        marketplace: {
            title: "Маркетплейсы",
            selector: "marketplace",
        },
        b2b: {
            title: "B2B",
            selector: "b2b",
        },
        distributor: {
            title: "Дистрибьюторы",
            selector: "distributor-frame",
        },
        confMessage: {
            title: "Вторая Международная Конференция <br/><span>по Маркетплейсам </span>в Узбекистане",
            selector: "description",
        },
        seller: {
            title: "Селлеры",
            selector: "seller",
        },
        manufacturer: {
            title: "Производители",
            selector: "manufacturer",
        }
    }
    // Скрываем элемент с id 'projector' при загрузке страницы
    hideProjector();

    return (
        <div className="sellerconf">
            {/* Верхний блок страницы sellerconf */}
            <div className="sellerconf-upper">
                <div className="sellerconf-upper__items">

                    {/* Логотип sellerconf */}
                    <div className="sellerconf-upper__item logo">
                        <div className="sellerconf-upper__item-img">
                        </div>
                    </div>

                    {/* Элементы с различными категориями товаров */}
                    {Object.entries(frames).map(([key, value]) => (
                        <div key={key} className={`sellerconf-upper__item ${value.selector}`}>
                            <div className="sellerconf-upper__item-title">
                                <span dangerouslySetInnerHTML={{__html: value.title}}></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Нижний блок страницы sellerconf */}
            <div className={"seller-conf-form"}>
                <SellerConfForm/>
                <SellerConfText/>
            </div>

        </div>
    );
}

export default SellerConfPage;
