import {API_URL} from "../utils/config";
import {useEffect, useState} from "react";
import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const FooterInfo = () => {
	const [company, setCompany] = useState('');
	const [email, setEmail] = useState('');
	const [language, setLanguage] = useLocalStorage('language', 'ru');

	const getData = async () => {
		const company = await fetch(`${API_URL}/site/footer.company`, {body:JSON.stringify({language: language}), method:"POST"}).then(response => response.text()).then(data => {return data});
		const email = await fetch(`${API_URL}/site/footer.email`, {body:JSON.stringify({language: language}), method:"POST"}).then(response => response.text()).then(data => {return data});


		setCompany(company)
		setEmail(email)
	}

	useEffect(() => {
		getData();
	}, []);

	return(
		<>
			<div dangerouslySetInnerHTML={{ __html: company }}></div>
			<div dangerouslySetInnerHTML={{ __html: email }}></div>
		</>
	)

}

export default FooterInfo;