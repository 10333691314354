import {Outlet} from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import {useEffect, useState} from "react";
import MultiModal from "./MultiModal";
import Overlay from "./Overlay";
import Cookies from "js-cookie";
import {useDispatch} from "react-redux";
import {PROJECTOR} from "../utils/constants";

const Layout = ({userData}) =>{
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	PROJECTOR.setAttribute('width', windowWidth)

	const dispatch = useDispatch();

	useEffect(()=>{
		const favoriteProductCookies = Cookies.get('favoriteProduct') ? JSON.parse(Cookies.get('favoriteProduct')) : []
		dispatch({type: "SET_FAVORITE_LIST", favoriteList: favoriteProductCookies})
	},[])

	return(
		<div className={'apicore'}>
			<Header userData={userData}/>
			<MultiModal/>
			<Overlay/>
			<main>
				<Outlet/>
			</main>
			<footer>
				<Footer/>
			</footer>
		</div>
	)
}

export default Layout;